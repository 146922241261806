import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/layouts/blogPost.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "tldr",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#tldr",
        "aria-label": "tldr permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`TL;DR`}</h1>
    <ul>
      <li parentName="ul">{`In an effort to continue decentralizing, we have created a mechanism for the community to host the Uniswap Interface`}</li>
      <li parentName="ul">{`The open-source `}<a parentName="li" {...{
          "href": "https://github.com/Uniswap/uniswap-interface"
        }}>{`Uniswap Interface`}</a>{` built by our team and
community is `}<a parentName="li" {...{
          "href": "https://github.com/Uniswap/uniswap-interface/releases"
        }}>{`automatically deployed`}</a>{` daily to IPFS`}</li>
      <li parentName="ul">{`Community members can pin the IPFS hashes to ensure availability`}</li>
      <li parentName="ul">{`We use IPNS + DNSLink to point `}<inlineCode parentName="li">{`/ipns/app.uniswap.org`}</inlineCode>{` to the latest `}<a parentName="li" {...{
          "href": "https://github.com/Uniswap/uniswap-interface/releases"
        }}>{`IPFS release`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://app.uniswap.org"
        }}>{`app.uniswap.org`}</a>{` is now served exclusively from the latest
`}<a parentName="li" {...{
          "href": "https://github.com/Uniswap/uniswap-interface/releases"
        }}>{`IPFS release`}</a>{`, however any IPFS gateway can be used directly`}</li>
      <li parentName="ul">{`The URL `}<a parentName="li" {...{
          "href": "https://uniswap.exchange"
        }}>{`uniswap.exchange`}</a>{` now forwards to `}<a parentName="li" {...{
          "href": "https://app.uniswap.org"
        }}>{`app.uniswap.org`}</a></li>
      <li parentName="ul">{`The ENS contenthash for `}<inlineCode parentName="li">{`uniswap.eth`}</inlineCode>{` now points to the latest IPFS release allowing the URL `}<a parentName="li" {...{
          "href": "https://uniswap.eth.link/"
        }}>{`uniswap.eth.link`}</a>{` to be used`}</li>
    </ul>
    <h2 {...{
      "id": "interfaces-and-decentralization",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#interfaces-and-decentralization",
        "aria-label": "interfaces and decentralization permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Interfaces and decentralization`}</h2>
    <p>{`The Uniswap `}<strong parentName="p">{`protocol`}</strong>{` is trustless and decentralized because it lives entirely on-chain.
Anyone running an Ethereum node can interact with the contracts directly which will perform as programmed for as long as Ethereum exists.
However, not everyone wants to run a node. Instead, many users choose to interact with Uniswap through web interfaces,
trade aggregators, wallets, or other DAPPs that have integrated Uniswap natively in their smart contracts.`}</p>
    <p>{`When using an interface, users should verify the transactions they sign match the transaction presented by the interface.
However, it is not always easy to do this. This is why it is important to use reputable interfaces.
We’re thrilled to see a growing ecosystem of high-quality interfaces for Uniswap, including Argent, 1inch, Rainbow,
Paraswap, Zerion, Instadapp, and many more.`}</p>
    <p>{`Open source interfaces (including many of the above) allow users to verify the code they are interacting with does what
it claims. If a user runs the code locally, they can make transactions with confidence. However, as soon as the code
is hosted, `}<strong parentName="p">{`it is difficult for users to verify the website they are interacting has not been modified`}</strong>{`.`}</p>
    <p>{`This is one of the problem that IPFS `}<a parentName="p" {...{
        "href": "https://blog.cloudflare.com/e2e-integrity/"
      }}>{`aims to solve`}</a>{`.`}</p>
    <h2 {...{
      "id": "what-we-did",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-we-did",
        "aria-label": "what we did permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What we did`}</h2>
    <p>{`Our team has always cared about decentralization, security, and accessibility. This is why we built an
`}<a parentName="p" {...{
        "href": "https://github.com/Uniswap/uniswap-interface"
      }}>{`open-source interface`}</a>{` for Uniswap that the community can directly run,
verify and build upon. We’ve just taken another step forward by decentralizing the hosting of the Uniswap Interface using IPFS.`}</p>
    <p>{`Using `}<a parentName="p" {...{
        "href": "https://github.com/features/actions"
      }}>{`GitHub Actions`}</a>{`, the `}<a parentName="p" {...{
        "href": "https://github.com/Uniswap/uniswap-interface"
      }}>{`Uniswap Interface`}</a>{`
is now deployed at least once per day to IPFS. Each release is automatically `}<a parentName="p" {...{
        "href": "https://docs.ipfs.io/concepts/persistence/"
      }}>{`pinned`}</a>{`
using `}<a parentName="p" {...{
        "href": "https://pinata.cloud"
      }}>{`pinata.cloud`}</a>{`, a free IPFS pinning service.
The IPFS releases can be found `}<a parentName="p" {...{
        "href": "https://github.com/Uniswap/uniswap-interface/releases"
      }}>{`on GitHub`}</a>{`.`}</p>
    <p>{`This means the Uniswap Interface can now be accessed via IPFS directly, through a gateway such as `}<a parentName="p" {...{
        "href": "https://cloudflare-ipfs.com/ipns/app.uniswap.org/"
      }}>{`cloudflare-ipfs.com`}</a>{`, or by an `}<em parentName="p">{`alias`}</em>{` to the Cloudflare gateway at `}<a parentName="p" {...{
        "href": "https://app.uniswap.org"
      }}>{`app.uniswap.org`}</a>{`.`}</p>
    <p>{`The domain uniswap.exchange is now redirected to app.uniswap.org, which is an alias to the Cloudflare IPFS gateway that serves the Uniswap Interface from IPFS.`}</p>
    <h2 {...{
      "id": "how-we-did-it",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-we-did-it",
        "aria-label": "how we did it permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How we did it`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`app.uniswap.org`}</inlineCode>{` subdomain is given a CNAME record pointing at `}<inlineCode parentName="p">{`cloudflare-ipfs.com`}</inlineCode>{`.`}</p>
    <p>{`When a user visits the domain `}<inlineCode parentName="p">{`app.uniswap.org`}</inlineCode>{`, the browser first looks up the DNS record and finds a CNAME to `}<inlineCode parentName="p">{`cloudflare-ipfs.com`}</inlineCode>{`.
The server at `}<inlineCode parentName="p">{`cloudflare-ipfs.com`}</inlineCode>{`, i.e. Cloudflare’s IPFS gateway, looks up the
`}<a parentName="p" {...{
        "href": "https://docs.ipfs.io/concepts/dnslink/"
      }}>{`DNSLink record`}</a>{` for the subdomain.
That TXT record contains the IPFS hash of the latest release.`}</p>
    <p>{`Cloudflare’s IPFS gateway then fetches the content using the IPFS protocol and serves the interface to your browser via HTTPS.`}</p>
    <h2 {...{
      "id": "some-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#some-changes",
        "aria-label": "some changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Some changes`}</h2>
    <p>{`Because IPFS gateways will not default to serving `}<inlineCode parentName="p">{`/index.html`}</inlineCode>{` as is expected by many single page applications, the Uniswap Interface uses a “hash” based router.`}</p>
    <p>{`This means that links that contain paths, such as `}<a parentName="p" {...{
        "href": "https://app.uniswap.org"
      }}>{`app.uniswap.org/swap`}</a>{` will no longer work, but `}<a parentName="p" {...{
        "href": "https://app.uniswap.org/#/swap"
      }}>{`app.uniswap.org/#/pool`}</a>{` will work.`}</p>
    <h2 {...{
      "id": "security-unicorn",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#security-unicorn",
        "aria-label": "security unicorn permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Security Unicorn`}</h2>
    <p>{`Some settings on the Uniswap Interface use localstorage, which is shared across users in some IPFS gateways.`}</p>
    <p>{`When using an IPFS gateway and referencing an IPFS hash or IPNS name by the `}<em parentName="p">{`path`}</em>{`
(e.g. `}<a parentName="p" {...{
        "href": "https://cloudflare-ipfs.com/ipfs/QmdJApBwfsGua9vKnMbswGFGA4y5Kj2VNNPhvcsc8NC7iA/"
      }}>{`cloudflare-ipfs.com/ipfs/QmdJApBwfsGua9v…/`}</a>{`)
rather than the `}<em parentName="p">{`subdomain`}</em>{`
(e.g. `}<a parentName="p" {...{
        "href": "https://bafybeig6hsm6lj74ertjf7hghsj2zrkzzpec5iyrt57vxiwxqltmgeeokm.cf-ipfs.com/"
      }}>{`bafybeig6hsm…cf-ipfs.com`}</a>{`),
other sites accessed from the same IPFS gateway can view and change your settings in the Uniswap Interface.`}</p>
    <p>{`To avoid this possibility, you can use the subdomain format of IPFS gateway URLs, which are contained in
`}<a parentName="p" {...{
        "href": "https://github.com/Uniswap/uniswap-interface/releases"
      }}>{`every release`}</a>{` along with the path format.`}</p>
    <h2 {...{
      "id": "verifying-a-build",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#verifying-a-build",
        "aria-label": "verifying a build permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Verifying a build`}</h2>
    <p>{`You can check what build you are being served from Cloudflare’s IPFS gateway by looking at your browser’s network console for the response headers sent directly from Cloudflare’s IPFS gateway.`}</p>
    <p>{`Cloudflare’s gateway uses the IPFS hash of the deployment in the `}<inlineCode parentName="p">{`etag`}</inlineCode>{` header, and reports the resolved IPNS path in the `}<inlineCode parentName="p">{`x-ipfs-path`}</inlineCode>{` header.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b946a3d34f58abc279ebed9d40bfcac4/49ee2/verifying-build.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAABYlAAAWJQFJUiTwAAACLUlEQVQ4y52U246aUBSGNU06jsakl62gGc4gcj4JglqZZmaSpnc9vEDf/xH+rr1GGDrapK3Jlw3E/bFOm9Hp+0+Ejz8Yu/3KOPff4MYFbMtEEIZomgY1sd8fsD8csdvtkCQJ8jxHFEXwPA++7zOjd6qP6V2AGfFG9pjxwoViucizFEmaYrPZwDBNmIRl27AsC6qqMpqmQdf1fh3RD9dYyhLatqWo9jgej7xuyxJRnJDQ7gUdL8LxGBeQUJJkTrUkScPpNiiKLfJtSakFMAzjN6m4Fs/+GKEkSSQouFZZljGiRiJ1sXEY1V8JZVnmVEUDRLqHwwExyf0gRBhGXM//EH5EVVW9tCwrFFu6rxusXZeb8k/CmjaKNN3zZkVRGHHd1XDYZRZOJhO85u3NDVbLJVIaGSGezWaY3t5iNp1iSsznc65xH91Q2BV4iEGYNGsVpSpwKMLlaoUFSTokepHCc/gq5e6mD59Qidhd45Ql2Nyt4Gsq1qslzMV7WNIHXm2a08Ix4egaFN77POSXQv1ZmDg2PtcVPhU5HqsSD0SbJmjp9NznGVriMU+R+h5cz+fZdOlEja7Nk4hSHLGKhrre1YijGB79uTuzYjX0l4Zw2c5cFQpsEsbUFE/MnONQ1DpU3egboJy73p3njgthd78myWlXoabUtnGEiuRFFCJLM3pRxkNe0DEMaNCH+68INY4ms018OdR4ojo+kPipqZlTGtPXx8NGpB8E/OUZ7v8FLvbIz65tlvYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "verifying build",
            "title": "verifying build",
            "src": "/static/b946a3d34f58abc279ebed9d40bfcac4/c1b63/verifying-build.png",
            "srcSet": ["/static/b946a3d34f58abc279ebed9d40bfcac4/5a46d/verifying-build.png 300w", "/static/b946a3d34f58abc279ebed9d40bfcac4/0a47e/verifying-build.png 600w", "/static/b946a3d34f58abc279ebed9d40bfcac4/c1b63/verifying-build.png 1200w", "/static/b946a3d34f58abc279ebed9d40bfcac4/49ee2/verifying-build.png 1618w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "how-you-can-help",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-you-can-help",
        "aria-label": "how you can help permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How you can help`}</h2>
    <p>{`To keep the Uniswap Interface available, you can pin the hash of the `}<a parentName="p" {...{
        "href": "https://github.com/Uniswap/uniswap-interface/releases/latest"
      }}>{`latest release`}</a>{`.`}</p>
    <p>{`If this sort of work sounds cool to you, we’re hiring! `}<a parentName="p" {...{
        "href": "mailto:contact@uniswap.org"
      }}>{`Shoot us a message!`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      